@import "styles/app.scss";

.topBlock {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include bgBlueWithNoise;
  position: relative;
}

.bgAccentOpacity {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: all .2s;
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  width: 100%;
  max-width: 480px;
  position: absolute;
  text-align: center;
  color: $colorWhite;

  h1 {
    font-size: 64px;
    letter-spacing: -2.56px;
    line-height: normal;
    margin-bottom: 10px;

    @include mediaSm {
      font-size: 86px;
      line-height: 94px;
    }

    @include mediaMd {
      font-size: 100px;
      line-height: 108px;
    }

    @include mediaLg {
      font-size: 140px;
      line-height: 150px;
      letter-spacing: -4px;
    }

    @include mediaGt {
      font-size: 170px;
      line-height: 180px;
    }

    @include mediaEx {
      font-size: 200px;
      letter-spacing: -8px;
    }
  }

  p {
    max-width: 300px;

    @include mediaLg {
      max-width: 400px;
    }
  }

}