@import "styles/app.scss";

.best {
  @include bgBlueWithNoise;
  position: relative;
  z-index: 1;
  top: -1px;

  &__title {
    @media (max-width: $screenSm) {
      br {
        display: none;
      }
    }
  }

  &__inner {
    padding-top: 60px;
    padding-bottom: 50px;
    color: $colorWhite;
  }

  &__tabs {
    position: relative;
    margin-top: 28px;
  }

  [class*="react-tabs__tab-panel--selected"] {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    -webkit-animation: fadeTabPanel 1s linear;
    animation: fadeTabPanel 1s linear;
    justify-items: center;
  }

  @-webkit-keyframes fadeTabPanel {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeTabPanel {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &__tabList {
    margin-bottom: 20px;
    padding: 0;
    display: flex;

    li {
      padding: 10px 20px;
      color: rgba(255, 255, 255, 0.5);
      border: none;
      cursor: pointer;
      background: transparent !important;
      border-radius: 0;
    }

    [class*="react-tabs__tab--selected"] {
      color: $colorWhite;
      border-bottom: 1px solid $colorWhite;
    }

    [class*="react-tabs__tab"]:focus:after {
      background: transparent !important;
    }
  }

  &_buttonWrapper {
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
}

@include mediaSm {
  .best {
    [class*="react-tabs__tab-panel--selected"] {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}

@include mediaLg {
  .best {

    [class*="react-tabs__tab-panel--selected"] {
      margin-top: 40px;
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
    }
  }
}

@include mediaGt {
  .best {
    &__tabList {
      display: flex;
      justify-content: flex-end;
    }

    &__tabs {
      margin-top: -56px;
    }

  }
}

@include mediaEx {
  .best {

    &__inner {
      padding-top: 100px;
      padding-bottom: 100px;
      .best__tabs {
        z-index: 7;

        ul {
          position: absolute;
          top: -100px;
          right: 0;
        }
      }
    }

    [class*="react-tabs__tab-panel--selected"] {
      margin-top: 100px;
    }

    &_buttonWrapper {
      margin-top: 40px;
    }
  }
}

@include mediaXx {
  .best {
    &__inner {
      padding: 121px 0 133px;
    }

    [class*="react-tabs__tab-panel--selected"] {
      gap: 18px;
    }
    &_buttonWrapper {
      margin-top: 48px;
    }
  }
}
