@import "styles/app.scss";

.offersSlider {
  margin: 0 auto;
  display: flex;

  &__wrapper {
    position: relative;
  }

  &__buttons {
    position: absolute;
    content: "";
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 18px;
    bottom: -15px;
    translate: 0 100%;
    top: 50%;
    translate: 0 -50%;
    justify-content: space-between;
    pointer-events: none;
    > button {
      pointer-events: all;
    }
    @include mediaLg {
      display: flex;
    }
  }
  &__buttons--mobile {
    position: absolute;
    content: "";
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 18px;
    bottom: -20px;
    translate: 0 100%;
    top: 0;
    @include mediaLg{
      display: none;
    }
  }

  &_reviewNext {
    z-index: 3;
    pointer-events: all;
    opacity: 1;
    
    @include mediaLg {
      margin-left: auto;
      opacity: 0;
      pointer-events: none;
    }
  }
  &_reviewPrev {
    z-index: 3;
    pointer-events: all;
    opacity: 1;
   
    @include mediaLg {
      opacity: 0;
      pointer-events: none;
    }
  }
  &_reviewNext--visible,
  &_reviewPrev--visible {
    opacity: 1;
    pointer-events: all;
  }
}

@media (min-width: $screenSm) {
  .offersSlider {
    max-width: 100%;
  }
}
