/* fonts */
$fontManrope: var(--font-manrope, sans-serif), var(--font-sans, sans-serif), var(--font-sans-thai, sans-serif);
$fontTsars: var(--font-tttSarsA, serif), var(--font-serif, serif), var(--font-serif-thai, serif);
/* Colors */
$colorAccent: #22364A;
$colorGray: #848C98;
$colorWhite: #FFFFFF;
$colorDarkWhite: #D4D4D4;
$colorLightGray: #EFEFF1;
$colorError: #AE1616;
$colorErrorLight: #FFA2A2;
$colorPresentationBg: #f5f5f5;
$colorPresentationBgAdditional: #d3d3d3;
$colorPresentationBgBlur: #fffefa;
$colorBorder: #848C9833;
$colorGradient: linear-gradient(90deg, #4072A3, #7EA2BC) border-box;
$colorLightAccent: rgba($colorAccent, 0.1);

/* Media */
$media1440: 1440px;
$media1400: 1400px;
$media1320: 1320px;
$media1280: 1280px;
$media1200: 1200px;
$media1023: 1023px;
$media475: 475px;
$media0: 0;


