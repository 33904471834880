@import "/styles/app.scss";

.allServices {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-color: $colorLightGray;
    z-index: 0;
    overflow: hidden;
    
    &_noise {
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @include bgGrayWithNoise;
    }

    &_ellipse {
        display: none;
    }

    &_leaves {
        position: absolute;
        top: -20vw;
        right: -6vw;
        width: 120vw;
        min-width: 120vw;
        height: 707.255px;
        transform: rotate(11.442deg);
        background-size: cover;
        background-repeat: no-repeat;
        object-fit: cover;
    }

    &_leaf {
        display: none;
    }

    &_services {
        padding: 0px 3.333vw;
        padding-top: 6vw;

        .pos {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            z-index: 5;
            padding-top: 60px;
        }

        .h2Subtitle {
            color: $colorAccent;
        }

        .text {
            color: $colorGray;
            margin-top: 20px;
            margin-bottom: 20px;
            max-width: 338px;
        }

        .cardsWrapper {
            margin-top: 40px;
            gap: 16px;
            position: relative;
            z-index: 4;
            padding-bottom: 16px;
        }
    }
}


@media (min-width: $screenLg) {

    .allServices {

        &_ellipse {
            display: block;
            width: 890px;
            height: 890px;
            border-radius: 890px;
            background-color: $colorWhite;
            opacity: 0.7;
            filter: blur(200px);
            position: absolute;
            top: -300px;
            left: -200px;
            z-index: 2;
        }

        &_leaves {
            top: -50px;
            right: -40vw;
            width: 1474.391px;
            height: 1011.753px;
            opacity: 0.5;
        }

        &_leaf {
            display: block;
            position: absolute;
            width: 607px;
            height: 912px;
            top: 450px;
            left: -255px;
            z-index: 2;
            transform: rotate(26deg) rotateY(3.142rad);

            @include mediaGt {
                top: 405px;
            }

            @include mediaXx {
                width: 733px;
                height: 1102px;
                top: 450px;
                left: -210px;
            }
            svg {
                width: 607px;
                height: 912px;
                @include mediaXx {
                    width: 733px;
                    height: 1102px;
                }
            }
        }
       
    &_services {
        display: grid;
        grid-template-columns: 38% 58%;
        justify-content: space-between;
        position: relative;
        z-index: 4;
        padding: 0px;
        padding-top: 100px;

        .pos {
            padding-top: 30px;
        }

        .cardsWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;
            margin-top: 0;
        }

        .text {
            margin-top: 20px;
            margin-bottom: 30px;
            max-width: 463px;
        }
    } 
    }   
}

@media (min-width: $screenXx) {
    .allServices {
        &_leavesVideo {
            top: -8vw;
            left: -845px;
            scale: 0.6;
        }

        &_services {
            .cardsWrapper {
                gap: 18px;
            } 
        }
    }
}

