@import "variables";
@import "_media.scss";
@import "_keyframes.scss";
@import "_mixins.scss";

.container {
  max-width: 1624px;
  padding-inline: 16px;
  margin: 0 auto;

  @include mediaEx {
    max-width: $screenEx;
    padding-inline: 48px;
  }

  @include mediaXx {
    max-width: $screenXx;
    padding: 0;
  }
}

.header-container {
  max-width: 1624px;
  padding-inline: 16px;
  margin: 0 auto;

  @include mediaEx {
    max-width: $screenEx;
    padding-inline: 48px;
  }

  @include mediaXx {
    max-width: $screenXx;
    padding: 0;
  }
}

.tab {
  &__list {
    @include tablist;
    &--dark {
      @include tablistDark;
    }
    &--top-border {
      @include tablistTopBorder;
    }
    &--bottom-border {
      @include tablistBottomBorder;
    }
    &--bottom-border-light {
      @include tablistBottomBorderLight;
    }
  }
  &__light {
    @include tabLight;
  }
  &__dark {
    @include tabDark;
  }
  &__button {
    @include tabButton;
  }
  &__tracker {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    border-radius: 50px;
    background: $colorAccent;
    z-index: 0;
  }
}

.h1 {
  @include h1;
}

.h2 {
  @include h2;
}

.h3 {
  @include h3;
}

.h4 {
  @include h4;
}

.h24 {
  @include h24;
}

.p1 {
  @include p1;
}

.p1text {
  @include p1text;
}

.p15 {
  @include p15;
}
.p2 {
  @include p2;
  &--nomedia {
    @include p2(false);
  }
}

.p3 {
  @include p3;
  &--nomedia {
    @include p3(false);
  }
}

.p4 {
  @include p4;
}
.p45 {
  @include p45;
}

.p45 {
  @include p45;
}

// DatePicker

.rmdp-day-picker {
  display: flex;
  gap: 36px;

  @media (max-width: $screenSm) {
    padding: 0 !important;
  }
}

.rmdp-wrapper {
  box-shadow: none !important;
}

.rmdp-calendar {
  padding: 0 !important;
}

.rmdp-day span {
  font-size: 14px !important;
  color: $colorAccent !important;
}

.rmdp-week-day {
  color: $colorGray !important;
}

.rmdp-header-values {
  font-size: 14px !important;
  color: $colorAccent !important;
}

.rmdp-day,
.rmdp-week-day {
  height: 34px !important;
  width: 34px !important;

  @media (max-width: $screenSm) {
    display: flex;
    justify-content: center;
    width: 100% !important;

    span {
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 34px !important;
    }
  }
}

.rmdp-week {
  margin-bottom: 2px;
}

.rmdp-week-day {
  font-size: 14px !important;
  font-weight: 300 !important;
}

.rmdp-range-hover,
.rmdp-range {
  background: $colorLightGray !important;
  box-shadow: none !important;

  &:focus,
  &.end,
  &.start {
    span {
      position: relative;
      height: 100% !important;
      color: $colorWhite !important;
      background: $colorAccent !important;
      z-index: 1;
    }
  }

  &:not(.end):focus,
  &.start {
    span::before {
      position: absolute;
      inset: 2px;
      border-radius: 100%;
      border: 0.5px solid $colorWhite;
      content: "";
    }
  }
}

.rmdp-range:focus,
.rmdp-range.start {
  border-radius: 50% 0 0 50% !important;
}

.rmdp-range.end {
  border-radius: 0 50% 50% 0 !important;
}

.rmdp-day {
  span {
    font-weight: 300 !important;
    inset: 0 !important;
  }

  &.rmdp-disabled {
    color: $colorGray !important;

    span {
      text-decoration: line-through !important;
    }
  }
}

.rmdp-header {
  & > * {
    gap: 36px !important;
    align-items: center !important;
  }
}

.rmdp-arrow-container {
  align-items: center;
  position: absolute;
  height: 30px !important;
  width: 30px !important;

  &:hover {
    background-color: $colorLightGray !important;
    box-shadow: none !important;
  }
}

.rmdp-arrow {
  padding: 3px !important;
  margin-top: 0 !important;
  border-color: $colorAccent !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden):not(.start):not(.end) span:hover {
  border: 1px solid $colorAccent !important;
  color: $colorAccent !important;
  background: transparent !important;
}
