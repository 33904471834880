@import "styles/app.scss";

.care {
  position: relative;
  z-index: 0;

  // todo: убрать padding-top когда вернем отзывы
  padding-top: 80px;
  @include mediaLg {
    padding-top: 60px;
  }

  &::after {
    position: absolute;
    height: 50%;
    bottom: 0;
    left: 0;
    background-color: $colorLightGray;
    content: "";
    width: 100%;
    z-index: 0;
  }
  &__wrapper {
    position: relative;
    z-index: 3;
    max-width: 100%;
    margin: 0;
    padding: 0;
    @include mediaLg {
      max-width: inherit;
      margin: inherit;
      padding: inherit;
    }
  }
  &__noise {
    position: absolute;
    inset: 0;
    z-index: 2;
    @include bgGrayWithNoise;
  }
  // TODO: вернуть max-height: 700px mobile;
  &__imageWrapper {
    position: relative;
    margin: 0 auto;
    aspect-ratio: 374 / 627;
    max-height: 383px;
    width: 100%;

    @include mediaSm {
      max-height: 427px;
    }
    @include mediaLg {
      width: auto;
      max-height: 700px;
      aspect-ratio: 1344 / 700;
    }
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      object-position: 73%;

      @include mediaSm {
        object-position: bottom;
      }
    }
  }
  &__gradient {
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.70) -17.09%, rgba(0, 0, 0, 0.00) 92.98%);
      width: 100%;
      height: 87px;

    @include mediaLg {
      background: linear-gradient(360deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 93.86%);
      height: 300px;
    }
  }

  &__textWrapperMob {
    color: $colorWhite;
    margin-bottom: 38px;
    p {
      padding-top: 10px;
      padding-right: 26px;
    }

    @include mediaLg {
      display: none;
    }
    br {
      display: none;
    }
    @include mediaSm {
      br {
        display: block;
        padding-right: 1ch;
      }
    }
    @include mediaMd {
      br {
        display: none;
      }
    }
  }
  // TODO: remove display: none; in mobile
  &__textWrapper {
    display: none;
    color: $colorWhite;
    position: absolute;
    left: 16px;
    bottom: 24px;
    p {
      padding-top: 10px;
      padding-right: 26px;
    }

    @include mediaLg {
      display: block;
      bottom: 20px;
    }

    @include mediaGt {
      bottom: 40px;
    }

    @include mediaEx {
      bottom: 60px;
    }

    @include mediaXx {
      left: 69px;
      bottom: 70px;
    }
  }
}

@media (min-width: $screenXx) {
  .care {
    &__imageWrapper {
      aspect-ratio: 374 / 700;
      max-height: 846px;
      @include mediaLg {
        width: auto;
        aspect-ratio: 1624 / 846;
      }
    }
  }
}
