@import "variables";
@import "media";
/* fonts */

@mixin h1 {
  font-size: 32px;
  line-height: 38px;
  font-weight: 300;
  letter-spacing: -0.96px;
  font-family: $fontTsars;
  @include mediaLg {
    font-size: 72px;
    line-height: 84px;
    letter-spacing: -2.16px;
  }

  @include mediaXx {
    font-size: 87px;
    line-height: 87px;
    letter-spacing: -2.61px;
  }
}

@mixin h2 {
  font-family: $fontTsars;
  font-size: 24px;
  font-weight: 300;
  line-height: 38px;
  letter-spacing: -0.72px;
  @include mediaLg {
    font-size: 48px;
    line-height: 62px;
    letter-spacing: -1.44px;
  }

  @include mediaXx {
    font-size: 58px;
    line-height: 74px;
    letter-spacing: -1.74px;
  }
}

@mixin h24 {
  font-family: $fontTsars;
  font-weight: 300;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: -0.72px;

  @include mediaLg {
    font-size: 32px;
    line-height: 41.6px;
    letter-spacing: -1.74px;
  }
}

@mixin h3 {
  font-family: $fontManrope;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0;

  @include mediaLg {
    font-size: 26px;
    line-height: 36px;
  }

  @include mediaXx {
    font-size: 32px;
    line-height: 44px;
  }
}

@mixin h4 {
  font-family: $fontTsars;
  font-weight: 300;
  font-size: 24px;
  line-height: 38px;
  letter-spacing: -0.72px;

  @include mediaLg {
    font-size: 32px;
    line-height: 41.6px;
    letter-spacing: -1.74px;
  }
}

// заголовок
@mixin p1 {
  font-family: $fontManrope;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0;
  font-weight: 600;

  @include mediaXx {
    font-size: 22px;
    line-height: 36px;
  }
}

// если большой текст р1
@mixin p1text {
  font-family: $fontManrope;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;
  font-weight: 500;

  @include mediaLg {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0;
    font-weight: 600;
  }

  @include mediaXx {
    font-size: 22px;
    line-height: 36px;
  }
}

@mixin p15 {
  font-family: $fontManrope;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;

  @include mediaLg {
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 0;
  }

  @include mediaXx {
    font-size: 22px;
    line-height: 36px;
  }
}

@mixin p2($includeMedia: true) {
  font-family: $fontManrope;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.14px;
  @if $includeMedia {
    @include mediaXx {
      font-size: 17px;
      line-height: 29px;
      letter-spacing: -0.51px;
    }
  }
}

@mixin p3($includeMedia: true) {
  font-family: $fontManrope;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.14px;
  @if $includeMedia {
    @include mediaXx {
      font-size: 17px;
      line-height: 29px;
      letter-spacing: 0.17px;
    }
  }
}

@mixin p3-input($includeMedia: true) {
  font-family: $fontManrope;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.16px;
  @if $includeMedia {
    @include mediaXx {
      font-size: 17px;
      line-height: 29px;
      letter-spacing: 0.17px;
    }
  }
}

@mixin p4($includeMedia: true) {
  font-family: $fontManrope;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0;
  @if $includeMedia {
    @include mediaXx {
      font-size: 15px;
      line-height: 24px;
    }
  }
}

// заголовок
@mixin p45 {
  font-family: $fontManrope;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;

  @include mediaXx {
    font-size: 15px;
    line-height: 24px;
  }
}

/* background */

@mixin darkGradient {
  background: linear-gradient(0deg, #252525, transparent);
}

@mixin bgFontColorBeforeHover($color) {
  background-image: linear-gradient(135deg, $color, $color);
}
@mixin bgGradient135 {
  background-image: linear-gradient(135deg, #4072a3, #7ea2bc);
}

@mixin bgGradient135Transition($class) {
  #{$class} {
    position: relative;
    &::before,
    &::after {
      width: 100%;
      height: 100%;
      content: "";
      position: absolute;
      inset: 0;
      transition: opacity 0.2s;
    }
    &::after {
      @include bgGradient135;
      opacity: 0;
    }
    &::before {
      opacity: 1;
      background-image: linear-gradient(135deg, transparent, transparent);
    }
  }
  &:hover {
    #{$class}::after {
      opacity: 1;
    }
    #{$class}::before {
      opacity: 0;
    }
  }
}

@mixin bgGradient135Text($color) {
  color: rgba($color, 1);
  background: linear-gradient(135deg, #4072a3, #7ea2bc);
  background-size: 200% 200%;
  transition: color 0.2s;

  background-clip: text;
  -webkit-background-clip: text;
  &:hover {
    color: rgba($color, 0);
  }
}

@mixin bgGradient277 {
  background-image: linear-gradient(277.37deg, #7ea2bc 17.22%, #4072a3 85.03%);
}

@mixin bgBlueWithNoise {
  background: linear-gradient(277.37deg, #7ea2bc 17.22%, #4072a3 85.03%),
    url("/noise.webp");
  background-size:
    100% 100%,
    846px 238px;
  background-repeat: no-repeat, repeat;
}

@mixin bgGrayWithNoise {
  opacity: 0.16;
  background-image: url("/noise_1.webp");
  background-color: $colorLightGray 0% 0%;
  background-repeat: repeat repeat;
}

@mixin bgLowWhite {
  background: linear-gradient(225deg, hsla(0, 0%, 100%, 0.2), hsla(0, 0%, 100%, 0.04));
}
@mixin bgWhite10 {
  background: rgba(white, 0.1);
}

// Matte Glass Light
@mixin bgMatteGlassLight {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(20px);
}

/* shadows */
@mixin lightBlackShadowFilter {
  filter: drop-shadow(0px 51px 64px rgba(0, 0, 0, 0.05));
}

/* misc */
@mixin hover-gradient-text($color: $colorAccent) {
  transition: all 0.3s;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-image: linear-gradient(277.37deg, $color 17.22%, $color 85.03%);
  &:hover {
    background-image: linear-gradient(277.37deg, #7ea2bc 17.22%, #4072a3 85.03%);
  }
}
@mixin scrollDisable {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin orderAndSpan($n, $order, $span: 1, $spanMobile: 1, $spanTablet: 1, $orderTablet: false) {
  &:nth-of-type(#{$n}) {
    order: $order;
    grid-column: span $spanMobile;
    @include mediaLg {
      grid-column: span $spanTablet;
      @if($orderTablet){

        order: $orderTablet;
      }
    }
    @include mediaGt {
      grid-column: span $span;
    }
  }
}

/* tabs */
@mixin tablist {
  position: relative;
  display: flex;
  justify-content: flex-start;
  @include scrollDisable;
  overflow: scroll;
  margin-inline: 0 !important;
  @include mediaLg {
    justify-content: center;
  }
}
@mixin tablistDark {
  background-color: $colorGray;
  width: min-content;
  padding: 4px;
  height: 48px;
  border-radius: 30px;
  .tab__tracker {
    background-color: $colorWhite;
    height: calc(100% - 8px);
    top: 4px;
  }
  .tab__button {
    &[class*="--selected"] {
      color: $colorAccent;
    }
    color: $colorWhite;
  }
}
@mixin tablistTopBorder {
  padding: 4px;
  height: 48px;
  border-radius: 30px;
  .tab__tracker {
    background-color: transparent;
    border-top: 1px solid $colorAccent;
    height: calc(100% - 8px);
    top: 4px;
    border-radius: 0px;
  }
  .tab__button {
    padding: 10px 20px;
    &[class*="--selected"] {
      color: $colorAccent;
    }
    color: $colorGray;
  }
}
@mixin tablistBottomBorder {
  padding: 4px;
  height: 48px;
  border-radius: 30px;
  .tab__tracker {
    background-color: transparent;
    border-bottom: 1px solid $colorAccent;
    height: calc(100% - 8px);
    top: 4px;
    border-radius: 0px;
  }
  .tab__button {
    padding: 10px 20px;
    &[class*="--selected"] {
      color: $colorAccent;
    }
    color: $colorGray;
  }
}
@mixin tablistBottomBorderLight {
  padding: 4px;
  height: 48px;
  border-radius: 30px;
  .tab__tracker {
    background-color: transparent;
    border-bottom: 1px solid $colorWhite;
    height: calc(100% - 8px);
    top: 4px;
    border-radius: 0px;
  }
  .tab__button {
    padding: 10px 20px;
    &[class*="--selected"] {
      color: $colorWhite;
    }
    color: $colorWhite;
  }
}

@mixin tabButton {
  position: relative;
  white-space: nowrap;
  transition: 0.2s;
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mediaLg {
    padding: 10px 24px;
  }
  & > * {
    position: relative;
    z-index: 1;
    font-weight: 700;
  }
  color: $colorAccent;
  &:hover:not([class*="--selected"]) {
    opacity: 0.8;
  }
  &[class*="--selected"] {
    cursor: default;
    border-radius: 50px;
    color: $colorWhite;
  }
}

@mixin tabLight {
  white-space: nowrap;
  border-bottom: 1px solid transparent;
  transition: 0.2s;
  cursor: pointer;
  padding: 10px;
  @include p1;
  @include mediaLg {
    padding: 10px 20px;
  }
  color: rgba($colorWhite, 0.5);
  &:hover {
    color: $colorWhite;
  }
  &[class*="--selected"] {
    cursor: default;
    color: $colorWhite;
    border-bottom: 1px solid $colorWhite;
  }
}

@mixin tabDark {
  white-space: nowrap;
  border-color: transparent;
  border-style: solid;
  background-color: transparent;
  border-bottom: 1px solid transparent;
  transition: 0.2s;
  cursor: pointer;
  padding: 10px;
  @include p1;
  @include mediaLg {
    padding: 10px 20px;
  }
  color: $colorGray;
  &:hover {
    color: $colorAccent;
  }
  &[class*="--selected"] {
    cursor: default;
    color: $colorAccent;
    border-bottom-color: $colorAccent;
  }
}

@mixin fadeInDown($duration: 2s, $delay: 0s) {
  opacity: 0;
  animation-name: fadeInDown;
  animation-delay: $delay;
  animation-duration: $duration;
  animation-fill-mode: both;

  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@mixin underlined {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 1px;

  &:hover {
    text-decoration: none;
  }
}

@mixin modalCloseBtn {
  position: absolute;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: hsla(0, 0%, 100%, 0.3);
  box-shadow: 0 51px 64px 0 rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  cursor: pointer;

  color: $colorAccent;
  @include mediaLg {
    color: $colorWhite;
  }
}

@mixin no-print {
  @media print {
    display: none;
  }
}