@import "styles/app.scss";

.main {
  &__overflow {
    overflow: hidden;
  }

  
}

.btnScroll {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 7;
}

