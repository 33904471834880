@import "/styles/app.scss";

.videoBlock {
  position: relative;
  background-color: $colorLightGray;

  &::after {
    position: absolute;
    height: 50%;
    bottom: 0;
    left: 0;
    @include bgBlueWithNoise;
    content: "";
    width: 100%;
    z-index: 2;
  }
  > div {
    @include mediaMax($screenLg) {
      padding-inline: 0;
    }
  }
  .videoContainer {
    position: relative;
    z-index: 1000;
    max-width: 100%;
    overflow: hidden;
    width: 100%;
    max-height: 700px;
    object-fit: cover;
    @include mediaLg {
      max-height: 700px;
    }
    @include mediaXx {
      max-height: 826px;
    }
  }
}
