@import "styles/app.scss";

.feedback {
  padding-top: 60px;
  padding-bottom: 44px;
  overflow: hidden;
  position: relative;

  @include mediaEx {
    padding: 82px 41px;
  }
  &--nopadding {
    width: 100vw;
    padding: 300px 0 40px;
    @include mediaLg {
      width: 100%;
      padding-top: 40px;
    }
  }

  &__title {
    margin-bottom: 20px;
    max-width: 181px;

    @include mediaSm {
      max-width: 90%;
    }
    @include mediaMd {
      max-width: 100%;
    }
  }

  &__bg {
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
  }
  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 30px;

    @include mediaSm {
      margin-top: 0;
    }
    @include mediaLg {
      flex-direction: row;
    }
    @include mediaGt {
      align-items: center;
      gap: 200px;
    }
  }
  &__animation {
    position: absolute;
    top: 20px;
    right: -250px;
    width: 475px;
    height: 870px;
    transform: rotate(8deg);

    @include mediaLg {
      top: -100px;
      right: -200px;
      width: 607px;
      height: 1112px;
    }

    @include mediaEx {
      right: -150px;
    }

    &__flowerRight {
      transform: scale(1.04);
      width: 475px;
      height: 870px;
    }
  }

  &__animation--start {
    .feedback__flowerRight {
      -webkit-animation: show-flower 1.5s linear forwards;
      animation: show-flower 1.5s linear forwards;
      @keyframes show-flower {
        0% {
          transform: scale(1.04);
        }

        100% {
          transform: scale(1);
        }
      }
    }
  }

  &__text {
    color: $colorGray;
    max-width: 254px;

    @include mediaMd {
      max-width: 100%;
    }
  }

  &_formWrapper {
    align-self: flex-start;
  }
  &_form {
    max-width: 343px;
    margin-top: 30px;
    min-height: unset;
    min-width: unset;

    @include mediaMd {
      max-width: 70%;
    }
    @include mediaGt {
      max-width: 585px;
    }

    @include mediaXx {
      max-width: 700px;
    }
  }
}
