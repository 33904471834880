@import "styles/app.scss";

  .fivesteps {
    &__stepsWrapper {
        height: min-content;
        display: flex;
        overflow-y: scroll;
        @include scrollDisable;

        @include mediaLg {
          display: grid;
          overflow: hidden;
          overflow-x: scroll;
          position: relative;
          grid-template-columns: repeat(5, 1fr);
          width: 100%;
        }
    }

    &__stepsWrapper.twoRows {

      @include mediaLg {
        max-width: 830px;
        grid-template-columns: repeat(2, 1fr);
        gap: (40px 0);
      }

      @include mediaGt {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &__stepButtonWrapper {
      display: none;

      @include mediaEx {
        display: block;
        width: 100%;
        position: relative;
  
        &__button {
          position: absolute;
          top: 34%;
          left: 10px;
        }
      }
    }

    &__stepItem {
      width: 100%;
      &:last-of-type{
        overflow: hidden;
      }
    }

    &__step {
      position: relative;
      width: 100%;
      
    }

    &__stepText {
      width: 250px;
      padding-top: 10px;
      padding-left: 16px;
      max-width: 250px;

      @include mediaLg {
        width: unset;
      }

      &--light {
        color: $colorWhite;
      }

      &--dark {
        color: $colorAccent;
      }
    }

   
    &__number {
      font-size: 80px;
      font-family: $fontManrope;
      font-weight: 300;
      letter-spacing: -1.6px;
      padding-left: 16px;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @include mediaXx {
        font-size: 97px;
        letter-spacing: -1.94px;
      }

      &--light {
        color: $colorWhite;
        background-image: url("/noise-blue.webp");
        background-size: 100px;
        background-repeat: repeat;
      }
  
      &--dark {
        color: $colorAccent;
        background-color: $colorAccent;
      }
    }

    &__listItem_icon {
      width: 40px;
      height: 40px;
      
      &--light {
        color: $colorWhite;
      }

      &--dark {
        color: $colorAccent;
      }
    }

    &__lastImage {
      position: absolute;
      top: 80.5px;
      right: -22px;
    }
    &__lastImageThird {
      display: none;
    }
    &__stepLine {
      position: absolute;
      top: 99.5px;
      left: 11px;
      width: 100%;

      &--light {
        border-bottom: 1px solid $colorWhite;
      }

      &--dark {
        border-bottom: 1px solid $colorAccent;
      }
    }
    &__numbers {
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      @-webkit-keyframes fadeNumbers {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      @keyframes fadeNumbers {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }

  }

  @media (min-width: 997px) {
    .fivesteps {
      &__stepLine {
        top: 19px;
      }
      &__lastImage {
        top: 0;
        right: -10px;
      }
      &__lastImageLanding {
        position: absolute;
        top: 0;
        right: -22px;
      }
    }
  }

  @media (min-width: 1200px) {
    .fivesteps {
      &__lastImageThird {
        display: block;
        position: absolute;
        top: 0;
        right: -10px;
      }
  }
  }

  @media (min-width: 1440px) {
    .fivesteps {
      &__lastImageLanding {
        display: none;
      }
    }
  }