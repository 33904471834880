$screenXs: 414px;
$screenSm: 576px;
$screenMd: 768px;
$screen960: 960px;
$screenLg: 996px;
$screenGt: 1200px;
$screenEx: 1440px;
$screenXx: 1624px;

@mixin media($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin mediaMin($min) {
  @media (min-width: $min) {
    @content;
  }
}

@mixin mediaMax($max) {
  @media (max-width: $max) {
    @content;
  }
}

@mixin mediaXx {
  @media (min-width: $screenXx) {
    @content;
  }
}

@mixin mediaEx {
  @media (min-width: $screenEx) {
    @content;
  }
}
@mixin mediaGt {
  @media (min-width: $screenGt) {
    @content;
  }
}
@mixin mediaLg {
  @media (min-width: $screenLg) {
    @content;
  }
}

@mixin media960 {
  @media (min-width: $screen960) {
    @content;
  }
}
@mixin mediaMd {
  @media (min-width: $screenMd) {
    @content;
  }
}
@mixin mediaSm {
  @media (min-width: $screenSm) {
    @content;
  }
}
@mixin mediaXs {
  @media (min-width: $screenXs) {
    @content;
  }
}

@mixin printer {
  @media print  {
    @content;
  }
}
