@import "styles/app.scss";
  .build {
    position: relative;
    z-index: 2;

    &__advantages {
      padding-top: 60px;
      padding-bottom: 40px;
    }

    &__stepsTitle {
      color: $colorWhite;
      margin-bottom: 30px;
    }

    &__sliderBlock {
      position: relative;
      z-index: 1;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 13px;

    }

    &__listItem {
      color: $colorWhite;
      display: flex;
      align-items: flex-start;
      gap: 6px;
      p {

        margin: auto 0;
      }

      &_icon {
        width: 30px;
        height: 30px;
        flex: none;
      }
    }

    &__stepsBlock {
      padding-top: 60px;
      padding-bottom: 80px;
    }

    &__title {
      color: $colorWhite;
      margin-bottom: 23px;
    }
  }

@media (min-width: $screenSm) {
  .build {
    
    &__stepsBlock {
      padding-top: 120px;
    }
  }
}
@media (min-width: $screenGt) {
  .build {
    &__advantages {
      display: flex;
    }

    &__title {
      width: 50%;
      margin-bottom: 0;
    }

   
    &__sliderBlock .slick-track {
      display: flex;
      justify-content: flex-end;
    }

    &__list  {
      width: 50%;
      gap: 29px;
    }

    &__listItem {
      gap: 10px;
      &_icon {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@media (min-width: $screenXx) {
  .build {
    &__stepsBlock {
      padding-top: 145px;
    }

    &__stepsTitle {
      margin-bottom: 36px;
    }
  }
}

